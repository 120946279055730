import Prism from "prismjs";
import "prismjs/components/prism-markup-templating.js"; // required for any of the following language imports
import "prismjs/components/prism-c.min.js";
import "prismjs/components/prism-csharp.min.js";
import "prismjs/components/prism-json.min.js";
import "prismjs/components/prism-clike.min.js";
import "prismjs/components/prism-r.min.js";
import "prismjs/components/prism-php.min.js";
import "prismjs/components/prism-python.min.js";
import "prismjs/components/prism-bash.min.js";
import "prismjs/components/prism-http.min.js";
import "prismjs/components/prism-php.min.js";
import "prismjs/components/prism-java.min.js";
import "prismjs/components/prism-javascript.min.js";

export default Prism;
