export const blue = "#3A72D4";
export const darkBlue = "#204354";
export const text = "#204354";
export const green = "#41D592";
export const orange = "#ED7E41";
export const red = "#F5474F";
export const lightGray = "#D2D7E1";
export const lighterGray = "#F0F1F3";
export const sailorRed = "#F54562";
export const paper = "#FAFAF8";
