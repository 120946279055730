import { IDENTITY_CONFIG, METADATA_OIDC } from "../config/auth";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import DEV_MODE from "./devMode";

const shouldLog = DEV_MODE && false;

export default class AuthService {
  UserManager;

  showInternal;

  constructor(redirect) {
    this.redirect = redirect;

    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });

    if (shouldLog) {
      // Logger
      Log.logger = console;
      Log.level = Log.DEBUG;
    }

    this.UserManager.events.addUserLoaded(user => {
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError(e => {
      console.log("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.signinSilent();
    });

    const showInternalFlag = localStorage.getItem("show-internal");
    this.showInternal = showInternalFlag ? JSON.parse(showInternalFlag) : null;

    // When we log in (by clicking login), stomp show-internal flag
    this.UserManager.events.addUserLoaded(user => {
      localStorage.removeItem("show-internal");
      this.showInternal = false;
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {
      "";
    });
  };

  toggleShowInternal = () => {
    this.showInternal = !this.showInternal;
    localStorage.setItem("show-internal", JSON.stringify(this.showInternal));
  };

  showInternalContents = () => {
    return this.isAuthenticated() && this.showInternal;
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();

    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = token => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    this.redirect("/");
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`,
      ),
    );

    return !!oidcStorage && !!oidcStorage.id_token;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then(user => {
        console.log("signed in", user);
      })
      .catch(err => {
        console.log(err);
      });
  };
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      // const darkMode = localStorage.getItem("dark-mode");
      console.log("clear");
      localStorage.clear();
      this.redirect("/");
      // localStorage.setItem("dark-mode", darkMode);
    });
    this.UserManager.clearStaleState();
  };
}
