const slugify = text => {
  // only get text, not html
  const node = document.createElement("h1");
  node.innerHTML = text;
  text = node.innerText;
  node.remove();

  return text
    .trim()
    .toLowerCase()
    .replace(/é/g, "e")
    .replace(/[^\w]+/g, "-")
    .replace(/^-+|-+$/g, ""); // trim leading and trailing dashes
};

export default slugify;
