import { BrowserRouter as Router } from "react-router-dom";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./fonts/stylesheet.css";
import App from "./App";
import { AuthProvider } from "./providers/AuthProvider";
import reportWebVitals from "./reportWebVitals";
import AuthService from "./helpers/AuthService";

(async () => {
  const auth = new AuthService();

  try {
    const userData = await auth.UserManager.getUser();
    const token = userData.id_token;
    if (token) {
      // hit endpoint for verify user
      Render();
    } else {
      //render public
      Render();
    }
  } catch (error) {
    // render public
    Render();
  }
})();

const Render = () =>
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </React.StrictMode>,
    document.getElementById("root"),
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
