import React from "react";

import { AuthConsumer } from "../../providers/AuthProvider";

export const LogoutCallback = () => (
  <AuthConsumer>
    {({ signoutRedirectCallback }) => {
      signoutRedirectCallback();
      return <main id="main" className="loading" />;
    }}
  </AuthConsumer>
);
