import React from "react";

import { AuthConsumer } from "../../providers/AuthProvider";

export const Logout = () => (
  <AuthConsumer>
    {({ logout }) => {
      logout();
      return <main id="main" className="loading" />;
    }}
  </AuthConsumer>
);
