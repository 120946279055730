import * as Sentry from "@sentry/browser";

import { renderMd } from "./markdown.js";

const showErrorPage = e => {
  Sentry.captureException(e);

  renderMd(`# Something went wrong
  
Sorry, there was an error with your request. You can try [going home](/) or refreshing the page.`);

  if (e instanceof Error) {
    throw e;
  }
};

const handleFetchError = response => {
  if (!response.ok) {
    const e = Error(response.statusText);
    showErrorPage(e);
    throw e;
  }
  return response;
};

export { showErrorPage, handleFetchError };
