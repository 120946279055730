import { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

import searchContent from "../helpers/sidebarSearch.js";

const SlashIcon = styled.span`
  color: var(--dull-color);
  font-size: 0.8em;
  pointer-events: none;
  position: absolute;
  right: calc(var(--gutter) + 1em);
  top: calc(var(--gutter) + 12px);
  border: 1px solid var(--dull-color);
  border-radius: 4px;
  padding: 1px 5px;
`;

const Nav = ({ children, showInternal }) => {
  const [search, setSearch] = useState("");
  const [lastQuery, setLastQuery] = useState("");
  const [isInFocus, setIsInFocus] = useState(); // we have to track this in state so that we can change the UI through renders
  const inputRef = useRef();

  // these useCallbacks and related useEffect listen and act on keyboard search commands
  const focusOnSearch = useCallback(
    e => {
      if (!isInFocus) e.preventDefault();
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, search.length);
    },
    [search.length, isInFocus],
  );

  const keyboardSearchHandler = useCallback(
    e => {
      const userUsedSlashSearch = e.key === "/" && !isInFocus;
      if (userUsedSlashSearch) {
        focusOnSearch(e);
      }
    },
    [focusOnSearch, isInFocus],
  );

  useEffect(() => {
    window.addEventListener("keydown", keyboardSearchHandler);

    return () => window.removeEventListener("keydown", keyboardSearchHandler);
  }, [keyboardSearchHandler]);

  // this effect creates listeners for when we focus or blur, so we can do things to update the UI
  useEffect(() => {
    const focusInSetter = () => {
      setIsInFocus(document.activeElement === inputRef.current);
    };
    const focusOutSetter = evt => {
      if (evt.target === inputRef.current) setIsInFocus();
    };
    window.addEventListener("focusin", focusInSetter);
    window.addEventListener("focusout", focusOutSetter);

    return () => {
      window.removeEventListener("focusin", focusInSetter);
      window.removeEventListener("focusin", focusOutSetter);
    };
  }, []);

  const isMac = window.navigator.userAgent.toUpperCase().includes("MAC");
  const metaKey = isMac ? "Cmd" : "Ctrl";

  return (
    <nav>
      <input
        ref={inputRef}
        value={search}
        onChange={e => setSearch(e.target.value)}
        type="search"
        placeholder={
          isInFocus ? `🔍  ${metaKey} + F for native search` : "🔍  Search page"
        }
        autoComplete="off"
        onKeyUp={e => searchContent(showInternal, lastQuery, setLastQuery)(e)}
      />
      <span className="search-result-count"></span>
      {!isInFocus && !search && <SlashIcon>/</SlashIcon>}
      {/* The contents of `nav_contents` gets stomped by markdown when viewing a page generated by markdown */}
      <span id="nav_contents">{children}</span>{" "}
    </nav>
  );
};

export default Nav;
