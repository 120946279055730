import React from "react";

import { AuthConsumer } from "../../providers/AuthProvider";

export const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback();
      return <main id="main" className="loading" />;
    }}
  </AuthConsumer>
);
