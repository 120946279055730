import * as Sentry from "@sentry/browser";

import { useState, useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { renderMdUrl, renderMd } from "./markdown";
import slugify from "./slugify.js";
import { getDatasetPages, getChangelogPages } from "./getPages";
import { useGlobalListeners } from "./hooks";

import initHeaderSearch, { validPathFilter } from "../../helpers/headerSearch";

const useFetchPageMdAndRender = pages => {
  const location = useLocation();
  const history = useHistory();
  useGlobalListeners();

  const [datasetPage, setDatasetPage] = useState();
  const [datasetChildrenPages, setDatasetChildrenPages] = useState([]);

  // While the initial page is loading, start preparing all "dataset" pages. Once this loads, "combinedPages" below gets re-calculated
  // By having this as its own side effect, the page can load faster if you _aren't_ on a `/datasets/*` route.
  useEffect(
    () =>
      getDatasetPages()
        .then(datasetPages => {
          // create the main dataset browse page
          setDatasetPage({
            route: "/datasets",
            pages: datasetPages.map(page => ({
              ...page,
              actions: [
                {
                  name: "📊 Data Reference",
                  route: page.route,
                },
                {
                  name: "📖 API Reference",
                  route: `/apis/${slugify(page.api)}`,
                },
              ],
            })),
          });

          // create routes for each dataset page
          let childPages = [];
          datasetPages.forEach(page =>
            childPages.push({
              route: page.route,
              render: () => {
                renderMd(page.content);
                if (page.callback) page.callback();
              },
            }),
          );
          // now that we have dataset pages, set up header search
          const pagesToSearch = pages
            .concat(datasetPages)
            .filter(page => !page.description?.includes("DEPRECATED"));

          console.warn("Initiating `initHeaderSearch` on main path");
          initHeaderSearch(pagesToSearch, history);

          setDatasetChildrenPages(childPages);
        })
        .catch(e => {
          console.error(e);
          Sentry.captureException(e);

          // just in case something goes wrong fetching datasets, set up header search without them
          console.warn("Initiating `initHeaderSearch` on catch path");
          initHeaderSearch(pages, history);
        }),
    [pages, history],
  );

  const combinedPages = useMemo(() => {
    return [
      ...datasetChildrenPages,
      ...getChangelogPages().map(page => ({
        route: page.route,
        render: () => renderMd(page.content),
      })),
      ...pages.map(page => ({
        route: page.route,
        render: renderMdUrl(page.url, page.callback),
      })),
    ].filter(validPathFilter);
  }, [pages, datasetChildrenPages]);

  // when a user clicks a link of any kind, `location` updates and this side effect changes the dynamic content on the page
  const [currentPage, setCurrentPage] = useState();
  useEffect(() => {
    (async () => {
      let page = combinedPages.find(page => page.route === location.pathname);

      if (page && (!currentPage || currentPage.route !== page.route)) {
        page.render();
        setCurrentPage(page);
      } else if (!page) {
        setCurrentPage();
      }
    })();
  }, [location, combinedPages, currentPage]);

  return [combinedPages, datasetPage];
};

export default useFetchPageMdAndRender;
