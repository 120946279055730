import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import Link from "./Link";

const HomeMain = styled.main`
  display: block;
  height: 100vh;

  article {
    margin: auto;
    max-width: 800px;
    width: 100%;
  }

  h1:first-child {
    margin-top: 1.3rem;
  }
`;

const NotFound = ({ datasetsAreLoaded }) => {
  const location = useLocation();

  return (
    <HomeMain id="main" className={!datasetsAreLoaded ? "loading" : ""}>
      <article>
        {datasetsAreLoaded && ( // for /datasets/* routes, we don't want to show 404 if they're refreshing or freshly loading the page
          <>
            <h1>
              404: Page <code>{location.pathname}</code> Not Found
            </h1>
            Sorry, we seem to have lost track of that page... Maybe you'd like
            to go{" "}
            <Link rel="prefetch" to="/">
              Home
            </Link>
            ?
          </>
        )}
      </article>
    </HomeMain>
  );
};

export default NotFound;
