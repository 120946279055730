import React from "react";
import styled from "styled-components";

import loader from "../images/loader.gif";

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10rem;

  img {
    height: 3rem;
  }
`;

const Loader = () => (
  <LoadingWrapper>
    <img src={loader} alt="loading" />
  </LoadingWrapper>
);

export default Loader;
