import React from "react";
import {
  Link as ReactDomLink,
  NavLink as ReactDomNavLink,
} from "react-router-dom";

// Normally, using `react-router-dom`, all `<Link />` components are within the
// react component tree and work just fine

// However, since we're creating dynamic html based on markdown and rendering it
// via `dangerouslySetInnerHTML`, links created there are outside the component
// tree

// To get around this, in our `useGlobalListeners` custom hook, we add a
// listener for every anchor tag evnet, and if it is "genuine" (created manually
// in React), we leave it alone. Otherwise, we access the `history` object from
// `react-router-dom` and push a new route, which produces the same effect as
// clicking on a `Link` component.

const Link = props => <ReactDomLink {...props} data-genuine />;
export const NavLink = props => <ReactDomNavLink {...props} data-genuine />;

export default Link;
