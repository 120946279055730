const fetchLoader = (...args) => {
  const main = document.getElementById("main");
  main.classList.add("loading");
  return fetch(...args)
    .then(res => {
      main.classList.remove("loading");
      return res;
    })
    .catch(() => {
      main.classList.remove("loading");
    });
};

export default fetchLoader;
