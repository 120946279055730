const getAncestor = (el, selector) => {
  if (!el.parentElement) {
    return null;
  } else if (el.parentElement.matches(selector)) {
    return el.parentElement;
  } else {
    return getAncestor(el.parentElement, selector);
  }
};

let queryResults = [];
let queryResultIndex = 0;
const searchContent = (
  showInternal,
  lastQuery,
  setLastQuery = () => {},
) => evt => {
  const query = evt.target.value.trim().toLowerCase();
  if (lastQuery !== query) {
    const resultCount = document.querySelector(".search-result-count");
    resultCount.innerHTML = "";
    if (query) {
      resultCount.innerHTML = "Press Enter";
    }
  }

  if (evt.key === "Enter" && evt.target.value.length >= 1) {
    let currentNode = null;
    // check to see if we need to run a new search
    if (lastQuery !== query) {
      // reset things
      queryResults = [];
      setLastQuery(query);
      queryResultIndex = 0;

      // get all the nodes
      const walker = document.createTreeWalker(
        document.querySelector("article"),
        NodeFilter.SHOW_TEXT,
        n => {
          return (
            !getAncestor(
              n,
              "pre, .tab-handle-wrapper, .filtered-out" +
                (showInternal ? "" : ", .internal-only"),
            ) && n.textContent.toLowerCase().includes(query)
          );
        },
      );
      // let node;
      let traverse = true;
      // while (node = walker.nextNode()) {
      while (traverse) {
        const node = walker.nextNode();
        if (node) {
          if (!queryResults.includes(node.parentElement)) {
            queryResults.push(node.parentElement);
          }
        } else {
          traverse = false;
        }
      }
      currentNode = queryResults[queryResultIndex];
    }
    // if we don't need to run a new search, just go to the previous/next search result
    else if (queryResults.length) {
      // jump to the next or previous search result; wrap around the start/end of the results list
      queryResultIndex =
        (queryResultIndex + (evt.shiftKey ? -1 : 1)) % queryResults.length;
      if (queryResultIndex < 0) {
        queryResultIndex = queryResults.length - 1;
      }
      currentNode = queryResults[queryResultIndex];
    }

    if (currentNode) {
      // update the search result count display
      document.querySelector(".search-result-count").innerHTML = `${
        queryResultIndex + 1
      }/${queryResults.length}`;

      // if the node is in a non-selected tab, choose that tab
      const tab = getAncestor(currentNode, "[data-tab]");
      if (tab) {
        getAncestor(currentNode, ".tabs")
          .previousSibling.querySelector(`[data-tab="${tab.dataset.tab}"]`)
          .click();
      }
      // if the node is in a non-open toggle, open the toggle
      const toggle = getAncestor(currentNode, "[data-toggle]");
      if (
        toggle &&
        !toggle.previousElementSibling.classList.contains("active")
      ) {
        toggle.previousSibling.click();
      }

      // scroll to it
      if (currentNode.scrollIntoViewIfNeeded) {
        currentNode.scrollIntoViewIfNeeded(true);
      } else {
        currentNode.scrollIntoView({
          block: "center",
        });
      }

      // make it pop
      currentNode.style.transitionProperty = "box-shadow, background-color";
      currentNode.style.transitionDuration = "0.4s";
      currentNode.style.transitionTimingFunction = "ease-out";
      currentNode.classList.add("search-result");
      setTimeout(() => {
        currentNode.classList.remove("search-result");
      }, 1000);
    } else {
      document.querySelector(".search-result-count").innerHTML = "0/0";
    }
  }
};
export default searchContent;
