import { Fragment, useReducer, useEffect, useState } from "react";
import PropTypes from "prop-types";
import marked from "marked";
import styled from "styled-components";

import Link from "./Link";
import Nav from "./Nav.js";
import { sailorRed } from "./hubspot/colors";

const ScrollToTopButton = styled.button`
  transition: all 0.3s ease-out;
  position: fixed;
  right: 10rem;
  bottom: 0px;
  transform: ${({ viewable }) =>
    viewable ? "translateY(43px)" : "translateY(75px)"};
  display: flex;
  background-color: ${sailorRed};
  color: white;
  cursor: pointer;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  padding: 5px 12px 46px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.02em;
  border-radius: 2px;
  font-family: "Lato", sans-serif;

  @media only screen and (max-width: 600px) {
    display: none;
  }

  :hover {
    transform: translateY(39px);
  }
`;

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const Tags = ({ tags }) => (
  <div className="tags">
    {tags.map(tag => (
      <code key={tag}>{tag}</code>
    ))}
  </div>
);
Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};

const PopGridItem = ({ name, route }) => (
  <Link className="pop-grid-item" to={route}>
    {name}
  </Link>
);
PopGridItem.propTypes = {
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

const PopGrid = ({ actions }) => (
  <div className="pop-grid small">
    {actions.map(props => (
      <PopGridItem key={props.name + Math.random()} {...props} />
    ))}
  </div>
);
PopGrid.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func,
      category: PropTypes.string,
      description: PropTypes.string,
      internal: PropTypes.bool,
      name: PropTypes.string,
      route: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      url: PropTypes.string,
    }),
  ),
};

const Maintainer = ({ name, email }) => (
  <code>
    <a href={`mailto:${email}`}>{name}</a>
  </code>
);
Maintainer.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
};

const Maintainers = ({ maintainers }) => (
  <div className="maintainers internal-only tags">
    {maintainers.map((props, i) => (
      <Fragment key={i}>
        <Maintainer {...props} />{" "}
      </Fragment>
    ))}
  </div>
);
Maintainers.propsTypes = {
  maintainers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string,
      apis: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

const Page = ({
  name,
  description,
  tags,
  route,
  noHeaderLinks,
  actions,
  internal,
  maintainers,
}) => (
  <div
    className={`${internal && "internal-only"}`}
    data-tags={`${(tags || []).join("|") + "|"}`}
  >
    <h2>
      {noHeaderLinks ? <div>{name}</div> : <Link to={route}>{name}</Link>}
    </h2>
    {maintainers && maintainers.length ? (
      <Maintainers maintainers={maintainers} />
    ) : (
      ""
    )}
    {tags ? <Tags tags={tags} /> : ""}
    <span dangerouslySetInnerHTML={{ __html: marked(description) }} />
    {actions ? <PopGrid actions={actions} /> : ""}
  </div>
);
Page.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  route: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func,
      category: PropTypes.string,
      description: PropTypes.string,
      internal: PropTypes.bool,
      name: PropTypes.string,
      route: PropTypes.string,
      noHeaderLinks: PropTypes.bool,
      tags: PropTypes.arrayOf(PropTypes.string),
      url: PropTypes.string,
    }),
  ),
  internal: PropTypes.bool,
  maintainers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string,
      apis: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

const getTagCategory = tag => {
  if (["anz", "canada", "global", "singapore", "uk", "us"].includes(tag))
    return "Nations";
  else return "Tags";
};

const getTagName = tag =>
  ((
    {
      anz: "Australia / New Zealand",
      canada: "Canada",
      global: "Global",
      us: "United States",
      uk: "United Kingdom",
      singapore: "Singapore",
      "core lmi": "Core LMI",
    }[tag] || tag
  ).replace(/\b(.)/g, c => c.toUpperCase()));

const TagFilter = ({ tag, onClick, checked }) => (
  <label key={tag} title={getTagName(tag)}>
    <input
      type="checkbox"
      value={`${tag}`}
      onChange={onClick}
      checked={checked}
    />{" "}
    {getTagName(tag)}
  </label>
);
TagFilter.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  onClick: PropTypes.func.isRequired,
  tag: PropTypes.string.isRequired,
};

const TagFilters = ({ tags, onClick, myFilters }) => {
  const tagCategories = {};
  tags.forEach(tag => {
    const category = getTagCategory(tag.tag);
    if (tagCategories[category]) tagCategories[category].push(tag);
    else tagCategories[category] = [tag];
  });

  return (
    <>
      {myFilters && (
        <>
          <h2>My Filters</h2>
          {myFilters}
        </>
      )}
      {Object.entries(tagCategories).map(([category, tags], i) => (
        <Fragment key={category}>
          <h2>{category}</h2>
          {tags
            ?.sort((a, b) => a.tag.localeCompare(b.tag))
            .map(tag => (
              <TagFilter
                checked={tag.checked || ""}
                key={tag.tag}
                tag={tag.tag}
                onClick={() => onClick(tag.tag)}
              />
            ))}
        </Fragment>
      ))}
    </>
  );
};
TagFilters.propTypes = {
  onClick: PropTypes.func.isRequired,
  myFilters: PropTypes.node,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string.isRequired,
      checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    }),
  ),
};

const UL = styled.ul`
  margin: 0;
  padding: 0;
`;

const PageList = ({ pages = [] }) => (
  <UL aria-label="List of pages">
    {pages.map((props, i) => (
      <Page key={i} {...props}></Page>
    ))}
  </UL>
);
PageList.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func.isRequired,
      category: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      featured: PropTypes.bool,
      internal: PropTypes.bool,
      maintainers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          email: PropTypes.string,
          apis: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string),
      url: PropTypes.string,
    }),
  ),
};

const UNIQUE_AND_FILTERING = "UNIQUE_AND_FILTERING";
const UNIQUE_TAGS = "UNIQUE_TAGS";

const SearchablePageList = ({
  pages,
  myFilters,
  title,
  showInternal,
  filteringPages,
  minimumTagOccurances,
  children,
}) => {
  const [tags, dispatch] = useReducer(tagsReducer, []);
  const [scrolledFromTop, setScrolledFromTop] = useState(false);

  const handleScroll = () => {
    const isScrolled = window.pageYOffset > 0;
    if (isScrolled && scrolledFromTop) return;
    setScrolledFromTop(isScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    dispatch({
      type: UPDATE_TAG_FILTERS,
      pages,
      showInternal,
      filteringPages,
      minimumTagOccurances,
    });
  }, [filteringPages, pages, showInternal, minimumTagOccurances]);

  const filteredPages = pages.filter(page => {
    const checkedTags = tags.filter(tag => tag.checked);
    return checkedTags.every(checkedTag => page.tags?.includes(checkedTag.tag));
  });

  const content = (
    <>
      {title && <h1>{title}</h1>}
      {children}
      <PageList pages={filteredPages} />
    </>
  );

  return (
    <>
      <Nav showInternal={showInternal}>
        <TagFilters
          key={title}
          onClick={tag => dispatch({ type: TOGGLE, selectedTag: tag })}
          tags={tags.sort((a, b) => b.tag.localeCompare(a.tag))}
          myFilters={myFilters}
        />
      </Nav>
      <article>{content}</article>
      {filteredPages.length > 14 && (
        <ScrollToTopButton
          viewable={scrolledFromTop}
          onClick={scrollToTop}
          disabled={!scrolledFromTop}
        >
          Back to Top
        </ScrollToTopButton>
      )}
    </>
  );
};
SearchablePageList.propTypes = {
  filteringPages: PropTypes.bool,
  myFilters: PropTypes.node,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func.isRequired,
      category: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      featured: PropTypes.bool,
      internal: PropTypes.bool,
      maintainers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          email: PropTypes.string,
          apis: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string),
      minimumTagOccurances: PropTypes.number,
      url: PropTypes.string,
    }),
  ),
  showInternal: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export { SearchablePageList, PageList, Maintainers };

const inflateTags = tags =>
  tags.map(tag => ({
    tag,
  }));

const TOGGLE = "TOGGLE";
const UPDATE_TAG_FILTERS = "UPDATE_TAG_FILTERS";

const tagsReducer = (
  tags = [],
  {
    type,
    selectedTag,
    pages,
    showInternal,
    filteringPages,
    minimumTagOccurances = 2,
  },
) => {
  switch (type) {
    case TOGGLE:
      return tags.map(tag => ({
        ...tag,
        checked: tag.tag === selectedTag ? !tag.checked : tag.checked,
      }));
    case UPDATE_TAG_FILTERS:
      const tagCounts = {};
      let pagesClone = pages.map(page => ({ ...page, tags: page.tags || [] }));
      pagesClone.forEach(page =>
        (page.tags || []).forEach(tag => {
          if (page.internal && !showInternal) return;
          if (tagCounts[tag]) tagCounts[tag]++;
          else tagCounts[tag] = 1;
        }),
      );

      const UNIQUE_FILTER = {
        [UNIQUE_AND_FILTERING]: ([key, value]) => value,
        [UNIQUE_TAGS]: ([key, value]) =>
          value !== pagesClone.length && value >= minimumTagOccurances,
      };
      const uniqueTags = Object.entries(tagCounts)
        .filter(
          UNIQUE_FILTER[filteringPages ? UNIQUE_AND_FILTERING : UNIQUE_TAGS],
        )
        .map(([key, value]) => key);

      return inflateTags(uniqueTags);
    default:
      return tags;
  }
};
