import React from "react";
import { Route } from "react-router-dom";

import { AuthConsumer } from "../../providers/AuthProvider";

export const PrivateRoute = ({ component, ...rest }) => {
  const renderFn = Component => props => (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect, ...rest }) => {
        if (!!Component && isAuthenticated()) {
          console.log("authd");
          return <Component {...props} />;
        } else {
          console.log("not authd");
          signinRedirect();
          return <main id="main" className="loading" />;
        }
      }}
    </AuthConsumer>
  );

  return <Route {...rest} render={renderFn(component)} />;
};
