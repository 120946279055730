import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ClipboardJS from "clipboard/dist/clipboard.min.js";

import withProxy from "../../helpers/proxy.js";

import { Meta } from "../components/AgnitioExplorer";

import { renderHtml, renderMd, renderMdUrl } from "./markdown";
import fetchLoader from "./loader";
import Prism from "./prism";

export const useSmartScroller = () => {
  const location = useLocation();

  useEffect(() => {
    let checkCount = 0;
    const hash = window.location.hash.slice(1);
    if (hash) {
      const hashCheck = setInterval(() => {
        let anchor;
        try {
          anchor = document.querySelector(`.anchor[name=${hash}]`);
        } catch (e) {}
        if (anchor) {
          anchor.parentNode.scrollIntoView(true);
          clearInterval(hashCheck);
        }

        if (checkCount++ > 20) {
          clearInterval(hashCheck);
        }
      }, 100);
    } else {
      window.scroll(0, 0);
    }
  }, [location.pathname]);
};

export const useGlobalListeners = () => {
  useCopyPastaListener();
  useGeneratedLinkListener();
  useGeneratedButtonListeners();
};

const useGeneratedButtonListeners = () => {
  useEffect(() => {
    const handleMiscClicks = evt => {
      let target = evt.target;

      // handle dataset button clicks
      if (
        target.getAttribute("data-version") &&
        target.getAttribute("data-id")
      ) {
        document
          .querySelectorAll(".static-tab-handle")
          .forEach(tab => tab.classList.remove("active"));
        evt.target.classList.add("active");

        const url = `http://agnitio.emsicloud.com/meta/dataset/${target.getAttribute(
          "data-id",
        )}/${evt.target.dataset.version}`;

        fetchLoader(withProxy(url))
          .then(response => response.json())
          .then(data => {
            const results = document.getElementById("agnitio-explorer-results");
            renderHtml(results, Meta(url, data));
            Prism.highlightAllUnder(results);
          });
      }

      // handle tabs
      if (target.classList.contains("tab-handle")) {
        const parent = target.parentNode;
        // update tab handles
        parent.childNodes.forEach(child => child.classList.remove("active"));
        target.classList.add("active");
        // update tab content
        parent.nextElementSibling
          .querySelectorAll("[data-tab]")
          .forEach(child => {
            child.style.display =
              child.dataset.tab === target.dataset.tab ? "block" : "none";
          });
      }

      if (target.getAttribute("data-test-url")) {
        const mdUrl = document.getElementById("test-url").value;
        document.querySelector("article").remove();
        renderMdUrl(withProxy(mdUrl))();
      }

      if (target.getAttribute("data-test-md")) {
        const md = document.getElementById("test-markdown").value;
        document.querySelector("article").remove();
        renderMd(md);
      }

      // handle toggles
      if (target.classList.contains("toggle-handle")) {
        target.classList.toggle("active");
      }

      // handle pop-grid clicks
      if (
        target.classList.contains("pop-grid-item") &&
        target.tagName !== "A"
      ) {
        target.querySelector("a").click();
      }
    };
    document.addEventListener("click", handleMiscClicks);

    return () => {
      document.removeEventListener("click", handleMiscClicks);
    };
  }, []);
};

// create copy code functionality for buttons on code sections
const useCopyPastaListener = () => {
  useEffect(() => {
    const clipboard = new ClipboardJS("[data-clipboard-text]", {
      text: function (trigger) {
        return decodeURIComponent(trigger.getAttribute("data-clipboard-text"));
      },
    });
    clipboard.on("success", e => {
      e.trigger.innerText = "COPIED";
      setTimeout(() => {
        e.trigger.innerText = "COPY";
      }, 1500);
    });
  }, []);
};

// handles links generated from markdown (so that the page doesn't reload)
const useGeneratedLinkListener = () => {
  const history = useHistory();
  useEffect(() => {
    const onClickNav = evt => {
      let target = evt.target;

      const leavingSite = target.href && target.href.includes("http");
      const linkToLocationOnCurrentPage =
        target.href &&
        target.href.split("#")[0] === window.location.href.split("#")[0];
      const isEmailLink = target.href && target.href.includes("mailto:");

      const shouldConnectLinkToReactRouter =
        target.tagName.toLowerCase() === "a" &&
        !target.getAttribute("data-genuine") &&
        !linkToLocationOnCurrentPage &&
        !isEmailLink &&
        !leavingSite;

      if (shouldConnectLinkToReactRouter) {
        evt.preventDefault();
        evt.stopPropagation();

        history.push(target.href.split(window.location.origin)[1]);
      }
    };
    document.addEventListener("click", onClickNav);

    return () => {
      document.removeEventListener("click", onClickNav);
    };
  }, [history]);
};
