const renderHtml = (root, content) => {
  root.innerHTML = content;
};

const renderMainHtml = (title, content) => () => {
  document.title = title;
  renderHtml(document.getElementById("main"), content);
};

export { renderHtml, renderMainHtml };
