import * as Sentry from "@sentry/browser";

import withProxy from "../helpers/proxy.js";
import fetchLoader from "../markdown/helpers/loader.js";

import datasetsData from "./datasets.json";
import apiData from "./apis.json";

const getAgnitioDatasetTags = name => {
  let tags = [];
  tags.push(name.split(".")[1]);
  if (tags[0] === "ca") tags[0] = "canada";
  if (tags[0] === "ww") tags[0] = "global";
  tags.push("core lmi");

  const indicators = {
    occupation: ["occupation"],
    demographics: ["demographics"],
    industry: ["industry"],
    enrollments: ["education"],
    completers: ["education"],
    ipeds: ["education"],
    staffing: ["occupation", "industry"],
    tuition: ["education"],
    laborforce: ["demographics"],
    "irs.migration": ["demographics"],
    veterans_affairs: ["education"],
    export: ["input-output"],
    educationalattainment: ["demographics", "education"],
    grossregionalproduct: ["input-output"],
    import: ["input-output"],
  };

  Object.entries(indicators).forEach(([search, outputTags]) => {
    if (name.includes(search)) {
      tags = tags.concat(outputTags);
    }
  });

  if (name.endsWith("veterans")) {
    tags.push("demographics");
  }
  return tags;
};

async function getAgnitioDatasets() {
  let agnitioDatasets = [];
  try {
    agnitioDatasets = await fetch(
      withProxy("https://agnitio.emsicloud.com/meta/definitions"),
    )
      .then(response => response.json())
      .then(data =>
        data.datasets
          .filter(
            dataset =>
              dataset.title &&
              dataset.description &&
              !dataset.description.includes("Description skipped for now") &&
              dataset.description.length > 125, // filter out lacking descriptions
          )
          .map(({ name, title, description, versions }) => ({
            id: name,
            api: "Core LMI",
            title: title.replace(/^CA /, "Canada "),
            description,
            tags: getAgnitioDatasetTags(name),
            versions,
          })),
      );
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
  return agnitioDatasets;
}

const ACS_DESCRIPTION = `
# Description

This ACS data is pulled directly from the US Census Data API using ACS 5-year
estimates. We have translated ACS variables into representative metrics with 
descriptive names to make pulling the data more convenient and have ensured 
compatibility with Lightcast areas. Does not include Puerto Rico data.

_This product uses the Census Bureau Data API but is not endorsed or certified by the Census Bureau._

# Metrics

{metrics}

# Filters

* Area: nation, state, MSA, county (FIPS)
`;

async function getAcsDatasets() {
  const acsDatasets = [];
  try {
    const metrics = await fetchLoader(
      withProxy("https://emsiservices.com/acs/meta/metrics"),
    )
      .then(response => response.json())
      .then(data => data.data);

    const metricDescription = metrics
      .filter(m => m.title)
      .map(
        m => `
#### ${m.title}

${m.description}

${m.acs_census_variable_links.join("\n")}`,
      )
      .join("");

    acsDatasets.push({
      id: "acs",
      title: "ACS Indicators",
      description: ACS_DESCRIPTION.replace("{metrics}", metricDescription),
      api: "Lightcast ACS Indicators",
      tags: ["acs"],
    });
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
  return acsDatasets;
}

const getDatasets = async () => {
  return datasetsData.records
    .map(({ fields, id }) => ({
      id,
      title: fields.title,
      description: fields.description,
      api: apiData.records.find(record => record.id === fields.api[0]).fields
        .name,
      tags: fields.tags?.sort((a, b) => a.localeCompare(b)),
    }))
    .concat(await getAgnitioDatasets())
    .concat(await getAcsDatasets())
    .map(dataset => {
      dataset.fullDescription = dataset.description
        .replace(/(\n|^)# (.*)\n/g, "$1## $2")
        .replace(/""/g, '"');
      dataset.description = dataset.fullDescription
        .replace(/##/g, "#")
        .split("#")[1]
        .replace(/^\s*Description\s*/, "");
      return dataset;
    })
    .sort((a, b) => (a.title > b.title ? 1 : -1));
};

export default getDatasets;
