import { AuthConsumer } from "../../providers/AuthProvider";

export const Callback = ({ setUser }) => {
  return (
    <AuthConsumer>
      {({ signinRedirectCallback }) => {
        signinRedirectCallback();
        return <main id="main" className="loading" />;
      }}
    </AuthConsumer>
  );
};
