import { useState, useMemo, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import release from "../config/release";

import { interpret } from "xstate/lib/interpreter";

export const useMachine = (machine, { context, ...config } = {}) => {
  const [machineCopy] = useState(machine);
  const [state, setState] = useState(machineCopy.initialState);
  const [contextCopy] = useState(context);
  const [configCopy] = useState(config);
  const service = useMemo(
    () =>
      interpret(
        machineCopy
          .withContext({ ...machineCopy.context, ...contextCopy })
          .withConfig(configCopy),
      ),
    [contextCopy, configCopy, machineCopy],
  );

  useEffect(() => {
    service.onTransition(setState).start();
    return service.stop;
  }, [service]);

  return [state, service.send];
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  );
};

const appendFeedbackWidget = showInternal => {
  // appzi feedback widget script
  if (!showInternal) {
    const feedbackScript = document.createElement("script");
    feedbackScript.async = true;
    feedbackScript.src = "https://app.appzi.io/bootstrap/bundle.js?token=qdW3W";
    document.body.appendChild(feedbackScript);
  }
};

export const useInternalExternalSetup = (auth, isAuthenticated) => {
  // TODO: Once we're on next.js (or SSR equivalent), we'll do auth a lot better. The side effect
  // below depends on `auth` so that they still only fire once per app load, but the added benefit
  // is that we can check if the user is logged in. (Auth only ever changes once from context, so we
  // listen to `isAuthenticated`)
  useEffect(() => {
    let feedbackTimeoutScript;
    if (auth) {
      // set up sentry
      if (window.location.hostname !== "localhost") {
        Sentry.init({
          dsn: "https://1a8398c78e5e4d7a954c49375abea479@sentry.io/1502130",
          environment: auth.showInternalContents() ? "internal" : "public",
          release: release,
        });
        window.Sentry = Sentry;
      }

      feedbackTimeoutScript = setTimeout(
        () => appendFeedbackWidget(auth.showInternalContents()),
        5000,
      );
      if (auth.showInternalContents()) document.body.classList.add("internal");
    }

    // if there's a `user` and they're a Lightcaster, clear the old
    // timeout so the new one doens't fire the script
    return () => clearTimeout(feedbackTimeoutScript);
  }, [auth, isAuthenticated]);
};
