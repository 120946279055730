import marked from "marked";
import HTTPSnippet from "httpsnippet";
import * as Sentry from "@sentry/browser";

import { showErrorPage, handleFetchError } from "./errors.js";

import fetchLoader from "./loader.js";
import Prism from "./prism";
import renderSchema from "./renderSchema";
import slugify from "./slugify.js";

// generates nav html based on headings scraped from md
const generateNav = (headings, internalOnlySlugs) =>
  headings
    .map(
      ({ text, level, slug }) => `
          <h${level} ${
        internalOnlySlugs.includes(slug) ? 'class="internal-only"' : ""
      }>
            <a href="#${slug || text}">${text}</a>
          </h${level}>
        `,
    )
    .join("");

// renders heading html from md and saves them off for later use
// (nav, title, etc)
const headingHandler = () => {
  const navHeadings = [];
  let title = "title";
  return {
    render: (text, level) => {
      let slug = slugify(text);
      const originalSlug = slug;

      // the first H1 should be the page title
      if (level === 1 && title === "title") {
        title = text.split("<a")[0];
      } else if (level < 4) {
        const prevSlugs = navHeadings.filter(
          heading => heading.originalSlug === originalSlug,
        ).length;
        if (prevSlugs) {
          slug = `${originalSlug}-${prevSlugs}`;
        }
        // saves off headings of level 2 or 3
        navHeadings.push({ text, level, slug, originalSlug });
      }

      return `<h${level}>
        <a name="${slug || text}" class="anchor" href="#${slug}"></a>
        ${text}
      </h${level}>`;
    },
    getNavHeadings: () => navHeadings,
    getTitle: () => title,
  };
};

// languages for which to generate code examples
const EXAMPLE_LANGUAGES = [
  {
    title: "curl", // title of the example
    target: "shell", // HTTPSnippet target
    client: "curl", // HTTPSnippet client
    highlight: "bash", // prism language
  },
  {
    title: "Node",
    target: "node",
    client: "request",
    highlight: "javascript",
  },
  {
    title: "Java",
    target: "java",
    client: "okhttp",
  },
  {
    title: "C#",
    target: "csharp",
  },
  {
    title: "C",
    target: "c",
  },
  {
    title: "Python",
    target: "python",
    client: "requests",
  },
  {
    title: "PHP",
    target: "php",
  },
  // {
  //   title: "Ruby",
  //   target: "ruby"
  // },
  {
    title: "R",
    target: "r",
  },
  {
    title: "HTTPie",
    target: "shell",
    client: "httpie",
    highlight: "bash",
  },
];

// renders md code blocks to html with customizations
const codeHandler = originalRender => {
  const render = (code, lang) => {
    if (lang === "json") {
      try {
        code = JSON.stringify(JSON.parse(code), null, "  ");
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      }
    }

    return (
      '<div class="code-wrapper">' +
      originalRender(code, lang) +
      `<span data-clipboard-text="${encodeURIComponent(code)}">COPY</span>` +
      "</div>"
    );
  };

  const renderHar = code => {
    let output = "";
    try {
      const snippet = new HTTPSnippet(JSON.parse(code));

      output =
        '<div class="tabs">' +
        EXAMPLE_LANGUAGES.map(exampleLang => {
          return (
            `<div data-tab="${exampleLang.title}">` +
            render(
              snippet.convert(exampleLang.target, exampleLang.client),
              exampleLang.highlight || exampleLang.target,
            ) +
            "</div>"
          );
        }).join("") +
        "</div>";
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
    }

    return output;
  };

  return {
    render: (code, lang) => {
      if (lang === "har") {
        return renderHar(code);
      } else if (lang === "jsonschema") {
        return renderSchema(JSON.parse(code));
      } else {
        return render(code, lang);
      }
    },
  };
};

// renders md code spans to html with customizations
const codespanHandler = originalRender => {
  const restVerbs = ["GET", "POST", "PUT", "PATCH", "DELETE", "OPTIONS"];
  return {
    render: code =>
      restVerbs.includes(code)
        ? `<span class="method ${code.toLowerCase()}">${code}</span>`
        : originalRender(code),
  };
};

// looks for toggle wrappers and adds corresponding handles
const addToggleHandles = element => {
  const toggles = element.querySelectorAll("[data-toggle]");
  for (let i = 0; i < toggles.length; i++) {
    const toggle = toggles[i];
    const handle = document.createElement("div");
    handle.setAttribute("class", "toggle-handle");
    handle.innerText = toggle.dataset.toggle;
    toggle.parentNode.insertBefore(handle, toggle);
  }
};

// looks for tab groups and adds corresponding handles
const addTabHandles = element => {
  const tabGroups = element.querySelectorAll(".tabs");
  for (let i = 0; i < tabGroups.length; i++) {
    const tabGroup = tabGroups[i];
    const tabs = tabGroups[i].querySelectorAll("[data-tab]");

    const tabHandleWrapper = document.createElement("div");
    tabHandleWrapper.setAttribute("class", "tab-handle-wrapper");

    let tabHandleHtml = "";
    for (let j = 0; j < tabs.length; j++) {
      const tab = tabs[j];
      const active = j === 0 ? "active" : "";
      tabHandleHtml += `<div class="tab-handle ${active}" data-tab="${tab.dataset.tab}">
        ${tab.dataset.tab}
      </div>`;
      if (!active) {
        tab.style.display = "none";
      }
    }
    tabHandleWrapper.innerHTML = tabHandleHtml;

    tabGroup.parentNode.insertBefore(tabHandleWrapper, tabGroup);
  }
};

// looks for youtube embeds and makes them responsive
const addResponsiveVideos = element => {
  const videos = element.querySelectorAll("iframe[src*=youtube]");
  for (let i = 0; i < videos.length; i++) {
    const wrapper = document.createElement("div");
    wrapper.setAttribute("class", "video-wrapper");
    videos[i].parentNode.insertBefore(wrapper, videos[i]);
    wrapper.appendChild(videos[i]);
  }
};

export const renderHtml = (root, content) => {
  root.innerHTML = content;
};

const renderMd = text => {
  // render the markdown
  const renderer = new marked.Renderer();
  const headingRenderer = headingHandler();
  renderer.heading = headingRenderer.render;
  renderer.code = codeHandler(renderer.code.bind(renderer)).render;
  renderer.codespan = codespanHandler(renderer.codespan.bind(renderer)).render;

  // create a fill new elements
  const article = document.createElement("article");
  article.innerHTML = marked(text, { renderer });

  // add toggle/tab handles and videos
  addToggleHandles(article);
  addTabHandles(article);
  addResponsiveVideos(article);

  // highlight code
  Prism.highlightAllUnder(article);

  // find headings that ought to be internal-only
  const internalOnlyAnchors = article.querySelectorAll(
    "article .internal-only h2 .anchor, article .internal-only h3 .anchor",
  );
  const internalOnlySlugs = [].slice
    .call(internalOnlyAnchors)
    .map(anchor => anchor.hash.slice(1));

  // update the dom
  const navContainer = document.getElementById("nav_contents");
  const navContents = generateNav(
    headingRenderer.getNavHeadings(),
    internalOnlySlugs,
  );

  navContainer.innerHTML = navContents;

  const main = document.getElementById("main");
  document.title = headingRenderer.getTitle();
  main.appendChild(article);
};

const renderMdUrl = (url, callback) => async () => {
  await fetchLoader(url)
    .then(handleFetchError)
    .then(response => response.text())
    .then(text => {
      renderMd(text);

      // jump to hash if one is present after content loads, or top of page
      let checkCount = 0;
      const hash = window.location.hash.slice(1);
      if (hash) {
        const hashCheck = setInterval(() => {
          let anchor;
          try {
            anchor = document.querySelector(`.anchor[name=${hash}]`);
          } catch (e) {}
          if (anchor) {
            anchor.parentNode.scrollIntoView(true);
            clearInterval(hashCheck);
          }

          if (checkCount++ > 20) {
            clearInterval(hashCheck);
          }
        }, 100);
      } else {
        window.scroll(0, 0);
      }

      if (callback) callback();
    })
    .catch(showErrorPage);
};

export { renderMd, renderMdUrl };
