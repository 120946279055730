import React, { useState, useEffect } from "react";
import AuthService from "../helpers/AuthService";
import { useHistory } from "react-router-dom";

export const AuthContext = React.createContext({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => ({}),
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
});

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = ({ children }) => {
  const [authService, setAuthService] = useState();
  const history = useHistory();
  const redirect = history.push;

  useEffect(() => {
    setAuthService(new AuthService(redirect));
  }, [redirect]);

  return (
    <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
  );
};
