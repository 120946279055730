import changelogData from "./changelogs.json";
import apis from "./apis.json";

const matchLikeIds = (records, jsonObjectName, fields) =>
  records
    .filter(({ id }) => (fields[jsonObjectName] || []).includes(id))
    .map(api => api.fields.name);

const changelogs = changelogData.records.map(({ fields }) => ({
  description: fields.description,
  name: fields.title,
  apis: matchLikeIds(apis.records, "apis", fields),
  tags: fields.tags?.sort((a, b) => a.localeCompare(b)),
}));

export default changelogs;
