import withProxy from "../helpers/proxy.js";

import apis from "./apis.json";
import pages from "./pages.json";
import taxonomies from "./taxonomies.json";

const matchLikeIds = (records, jsonObjectName, fields) =>
  records
    .filter(({ id }) => (fields[jsonObjectName] || []).includes(id))
    .map(api => api.fields.name);

export default pages.records.map(({ fields }) => ({
  name: fields.name,
  description: fields.description,
  category: fields.category,
  url: fields.url.startsWith("http") ? withProxy(fields.url) : fields.url,
  tags: fields.tags?.sort((a, b) => a.localeCompare(b)),
  internal: !fields.isPublic,
  loginRequired: fields.loginRequired,
  apis: matchLikeIds(apis.records, "apis", fields),
  taxonomies: matchLikeIds(taxonomies.records, "taxonomies", fields),
}));
