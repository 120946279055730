import withProxy from "../helpers/proxy.js";
import taxonomyData from "./taxonomies.json";
import maintainerData from "./maintainers.json";

const taxonomies = taxonomyData.records.map(({ fields }) => {
  return {
    description: fields.description,
    featured: fields.featured,
    internal: !fields.isPublic,
    maintainers: maintainerData.records
      .filter(({ id }) => (fields.maintainers || []).includes(id))
      .map(maintainer => maintainer.fields)
      .sort((a, b) => a.name.localeCompare(b.name)),
    name: fields.name,
    tags: fields.tags?.sort((a, b) => a.localeCompare(b)),
    url: withProxy(fields.docsUrl),
  };
});

export default taxonomies;
