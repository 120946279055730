import marked from "marked";

const isPrimitiveType = type => {
  const primitives = new Set([
    "string",
    "number",
    "boolean",
    "integer",
    "null",
  ]);
  return Array.isArray(type)
    ? type.filter(t => primitives.has(t)) > 0
    : primitives.has(type);
};

const getType = (schema, plural = false) => {
  if (Array.isArray(schema.type)) {
    return schema.type
      .map(t =>
        getType(
          {
            ...schema,
            type: t,
          },
          plural,
        ),
      )
      .join(" or ");
  }
  if (schema.type === "array" && schema.items) {
    return (
      "array" + (plural ? "s" : "") + " (" + getType(schema.items, true) + ")"
    );
  } else {
    return schema.type + (plural ? "s" : "");
  }
};

const getDetails = (schema, required) => {
  let details = `
    ${schema.__internal ? "<p>⚠️ <b>Internal Only</b></p>" : ""}
    ${!required ? "<p>This is an optional attribute.</p>" : ""}
    ${marked(schema.description || "")}
  `;

  details += [
    typeof schema.example !== "undefined"
      ? `Example: <code>${JSON.stringify(schema.example)}</code>`
      : "",
    typeof schema.examples !== "undefined"
      ? `Examples: <code>${schema.examples
          .map(JSON.stringify)
          .join("</code>, <code>")}</code>`
      : "",
    typeof schema.minimum !== "undefined"
      ? `Minimum: <code>${JSON.stringify(schema.minimum)}</code>`
      : "",
    typeof schema.maximum !== "undefined"
      ? `Maximum: <code>${JSON.stringify(schema.maximum)}</code>`
      : "",
    typeof schema.enum !== "undefined"
      ? `Must be one of these values: <code>${schema.enum
          .map(JSON.stringify)
          .join("</code>, <code>")}</code>`
      : "",
    typeof schema.default !== "undefined"
      ? `Default: <code>${JSON.stringify(schema.default)}</code>`
      : "",
  ]
    .filter(Boolean)
    .join("<br />");

  return details.trim();
};

const renderRows = (schema, key = "", required = false, vestigial = false) => {
  let output = "";

  // skip things tagged with __no_docs
  if (schema.__nodocs) return output;

  if (schema.anyOf && schema.type !== "object") {
    // a schema can have an `anyOf` key as an `Object` _or_ an `Array`. we _only_ want to get recursive for `anyOf` values if it's an _array_
    schema.anyOf.forEach(item => {
      output += renderRows(item, key, required, vestigial);
    });
    return output;
  }

  if (key && !vestigial) {
    let details = getDetails(schema, required);

    if (
      schema.type?.includes("array") &&
      schema.items &&
      isPrimitiveType(schema.items.type) &&
      !schema.items.__nodocs
    ) {
      details += "<p><b>Array Items:</b></p>" + getDetails(schema.items);
    }

    output += `<tr class="${schema.__internal ? "internal-only" : ""}">
        <td>
          <code>${key}</code>
          <div class="type">${getType(schema)}</div>
        </td>
        <td>
          ${marked(schema.title || "")}
          ${details ? `<div data-toggle="details">${details}</div>` : ""}
        </td>
      </tr>`;
  }

  if (schema.type?.includes("object")) {
    Object.entries(schema.properties || {}).forEach(([propKey, value]) => {
      output += renderRows(
        value,
        "<em>" + key + (key ? "." : "") + "</em>" + propKey,
        schema.required && schema.required.includes(propKey),
      );
    });
  }

  if (
    schema.type?.includes("array") &&
    schema.items &&
    !isPrimitiveType(schema.items.type)
  ) {
    if (Array.isArray(schema.items)) {
      schema.items.forEach(item => {
        output += renderRows(item, key + "[]", schema.minItems, true);
      });
    } else {
      output += renderRows(schema.items, key + "[]", schema.minItems, true);
    }
  }

  return output;
};

const renderSchema = schema => {
  let output = "";

  // object case
  if (schema.properties) {
    output += `
      <table class="schema-table">
        <thead>
          <tr><th>Property</th><th>Description</th></tr>
        </thead>
        <tbody>
          ${renderRows(schema)}
        </tbody>
      </table>
    `;
  }
  // array case
  else if (schema.type.includes("array")) {
    output +=
      `This must be an array of ${
        schema.minItems ? `at least ${schema.minItems}` : ""
      } ${getType(
        schema.items,
        schema.minItems !== 1,
      )}. Each item must be:<br />` + renderSchema(schema.items);
  }
  // primitive case
  else {
    output += getDetails(schema, true);
  }

  return output;
};

export default renderSchema;
