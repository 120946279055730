const Maintainer = ({ name, email }) => `
  <code><a href="mailto:${email}">${name}</a></code>
`;

const Maintainers = maintainers => `
  <div class="maintainers internal-only tags">
    ${maintainers.map(Maintainer).join("\n")}
  </div>
`;

export { Maintainers };
