const Json = data => `
  <pre class="language-json"><code class="language-json">${JSON.stringify(
    data,
    null,
    3,
  )}</code></pre>
`;

export const Meta = (url, metadata) => `
  <code>${url}</code>
  <h4>Metrics</h4>
  ${Json(metadata.metrics)}
  <h4>Dimensions</h4>
  ${Json(metadata.dimensions)}
  <h4>Attributes</h4>
  ${Json(metadata.attributes)}
`;

const VersionButtons = (id, versions) => `
  <div class="tab-handle-wrapper">
  ${versions
    .sort(String.localeCompare)
    .reverse()
    .map(
      version => `
      <button
        class="static-tab-handle"
        data-id=${id}
        data-version=${version}
      >
        ${version}
      </button>
    `,
    )
    .join("")}
  </div>
`;

const Explorer = ({ id, versions }) => {
  return `
    <p>
      This dataset is offered via the
      <a href="/apis/core-lmi">Core LMI API</a>. The
      metadata for this Core LMI API dataset is below.
    </p>
    <table>
      <tbody>
        <tr>
          <td>Dataset ID</td>
          <td><code>${id}</code></td>
        </tr>
        <tr>
          <td>Dataset URL</td>
          <td><code>https://agnitio.emsicloud.com/meta/dataset/${id}/{version}</code></td>
        </tr>
      </tbody>
    </table>
    <h3>Versions</h3>
    ${VersionButtons(id, versions)}
    <div id="agnitio-explorer-results"><em>Choose a version above to see full metadata</em></div>
  `;
};

export default Explorer;
