// set up global scope toggle fn
const toggleTheme = () => {
  document.body.classList.toggle("dark-mode");
  const isDarkMode = document.body.classList.contains("dark-mode");

  localStorage.setItem("dark-mode", JSON.stringify(isDarkMode));
};
export default toggleTheme;

const setInitialTheme = callback => {
  if (
    localStorage.getItem("dark-mode") &&
    JSON.parse(localStorage.getItem("dark-mode")) &&
    !document.body.classList.contains("dark-mode")
  ) {
    toggleTheme();
    callback();
  }
};
export { setInitialTheme };
